import Video from "@/common/partials/video/video"
import Block from "@/components/wrappers/block"
import styles from "./textVideoBlock.module.css"
import cx from "classnames"

export default function TextVideoBlock({
  headline,
  data,
  layout,
  children,
  gap = "normal",
  hovered = false,
  setHovered = () => {},
  className
}) {
  return (
    <Block
      width="bleed"
      gap={gap}
      className={cx(styles.videoBlock, styles["layout-" + layout], className)}>
      <Block width="full" gap={gap}>
        {headline && <h3 className={styles.headline}>{headline}</h3>}
        <div className={styles.videoGrid}>
          <Video
            data={data}
            className={styles.videoContainer}
            onMouseEnter={() => {
              setHovered(true)
            }}
            onMouseLeave={() => {
              setHovered(false)
            }}
            hovered={hovered}
          />
          <div className={styles.videoContent}>{children}</div>
        </div>
      </Block>
    </Block>
  )
}
