import HeadingLabel from "@/common/partials/headingLabel/headingLabel"
import cx from "classnames"

export default function ArticleContent({
  article,
  hideTag = false,
  setHovered = () => {},
  hovered = false
}) {
  // FIXME: Finns inte det här någon annanstans?
  const Meta = () => {
    const readTime =
      article.meta.dateline.readTime > 0
        ? article.meta.dateline.readTime + " min att läsa"
        : false

    const data = [
      article.meta.byline && "Av: " + article.meta.byline,
      !article.meta.dateline?.showModified
        ? article.meta.dateline.created
        : article.meta.dateline.modified,
      readTime
    ]
    return data.filter(Boolean).join(" • ")
  }

  return (
    <div className="flex flex-col items-start">
      {article.tag.label && !hideTag && (
        <HeadingLabel>
          <a href={article.tag.url}>{article.tag.label}</a>
        </HeadingLabel>
      )}
      <a
        className="pb-4"
        href={article.url}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <h2
          className={cx("text-has-hover-effect", {
            "text-hover-effect": hovered
          })}>
          {article.title}
        </h2>
      </a>
      <p className="mb-2 text-lg">{article.lead}</p>
      <div className="text-meta">
        <Meta />
      </div>
    </div>
  )
}
