export default function Play({ className = "w-20" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 22 28">
      <path d="M0 0v28l22-14L0 0Z" />
    </svg>
  )
}
